<template>
  <div
    class="plugin-editions-edition border dark:border-gray-700 rounded-md flex flex-col"
    :class="{
      'text-center px-8 py-10': context !== 'meta',
      'p-4': context === 'meta',
    }"
  >
    <div class="description flex-1">
      <h4
        class="edition-name inline-block font-bold mb-0"
        :class="{
          'text-3xl': context !== 'meta',
          'text-xl': context === 'meta',
        }"
      >
        {{ edition.name }}
      </h4>

      <template v-if="context !== 'meta'">
        <ul
          v-if="
            edition.features &&
            edition.features.length > 0
          "
          class="text-left my-8 border-t dark:border-gray-700"
        >
          <li
            v-for="(feature, featureKey) in edition.features"
            :key="featureKey"
            class="py-2 border-b dark:border-gray-700"
          >
            <c-icon icon="check" />
            {{ feature.name }}

            <info-hud v-if="feature.description">
              {{ feature.description }}
            </info-hud>
          </li>
        </ul>
      </template>
    </div>

    <div
      :class="{
        'lg:min-h-[8rem]': context !== 'meta',
      }"
    >
      <plugin-actions class="mt-4" :plugin="plugin" :edition="edition" />

      <template v-if="!isPluginEditionFree(edition)">
        <p class="mt-4 text-sm text-gray-500 dark:text-gray-400">
          Plus {{ $filters.currency(edition.renewalPrice) }}/year after one year.
        </p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {computed, toRefs, usePluginStoreStore} from '#imports';
  import type {Plugin, PluginEdition} from '@/stores/plugin-store';

  const pluginStoreStore = usePluginStoreStore();

  // Props
  const props = defineProps<{
    plugin: Plugin;
    edition: PluginEdition;
    context?: string;
  }>();

  const {plugin, edition, context} = toRefs(props);

  // Computed
  const isPluginEditionFree = computed(
    () => pluginStoreStore.isPluginEditionFree,
  );
</script>
