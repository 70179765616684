<template>
  <div class="info-hud inline-block">
    <VTooltip theme="dropdown">
      <a><c-icon icon="information-circle" /></a>

      <template #popper>
        <div class="p-6 max-w-xs">
          <slot />
        </div>
      </template>
    </VTooltip>
  </div>
</template>

<script>
  export default {
    props: {
      placement: {
        type: String,
        default: 'right',
      },
    },
  };
</script>

<style lang="scss">
  .info-hud {
    .v-popover {
      display: inline-block;
      line-height: 0;
      @apply text-blue-500;

      &:hover,
      &.open {
        @apply cursor-pointer;
      }

      & > span {
        outline: none;
      }
    }
  }

  .tooltip {
    max-width: 300px;
  }

  .v-popper--theme-dropdown {
    .v-popper__inner {
      @apply dark:text-white dark:bg-gray-700 dark:border dark:border-gray-600;
    }

    .v-popper__arrow-outer {
      @apply dark:border-gray-600;
    }
    .v-popper__arrow-inner {
      @apply dark:border-gray-700;
    }
  }
</style>
