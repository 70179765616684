<template>
  <div v-if="plugin && edition" class="plugin-actions">
    <c-btn
      kind="primary"
      :disabled="plugin.abandoned || isPluginEditionFree(edition)"
      :href="!isPluginEditionFree(edition) ? buyUrl : null"
      large
      block
    >
      <template v-if="!isPluginEditionFree(edition)">
        {{ $filters.currency(edition.price) }}
      </template>
      <template v-else>
        {{ 'Free' }}
      </template>
    </c-btn>

    <template v-if="plugin.abandoned">
      <div class="mt-4 text-grey-dark">
        This plugin is no longer maintained.
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import {storeToRefs} from 'pinia';
  import {computed, toRefs} from '#imports';
  import {useHelpers} from '~/composables/useHelpers';
  import {usePluginStoreStore} from '@/stores/plugin-store';

  const pluginStoreStore = usePluginStoreStore();
  const {isPluginEditionFree} = storeToRefs(pluginStoreStore);

  const {getPluginBuyUrl} = useHelpers();

  const props = defineProps<{
    plugin: object;
    edition: object;
  }>();

  const {plugin, edition} = toRefs(props);

  const buyUrl = computed(() => {
    return getPluginBuyUrl(plugin.value, edition.value);
  });
</script>
